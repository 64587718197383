import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import Menu from "./Menu"
import SocialMenu from "./SocialMenu"
import iconFCLogo from "../assets/svgs/FC_logo.svg"

const TopNavigation = ({ menuIsActive, handleMenuToggle }) => {
  const [phoneIsActive, setPhoneIsActive] = useState(false)

  const handlePhoneToggle = (e) => {
    e.preventDefault()
    setPhoneIsActive(!phoneIsActive)
  }
  return (
    <div id="nav-mobile-id">
      <header className="banner">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <Link className="brand" to="/" onClick={(event) => handleMenuToggle(event, false)}>
            <img src={iconFCLogo} alt="Logo" />
          </Link>
          <div className={`menu-access${menuIsActive ? " opened" : ""}`}>
            <span
              role="button"
              aria-label="Phone"
              tabIndex={0}
              className="phone-icon hide-outline fa-stack"
              onClick={handlePhoneToggle}
              onKeyPress={handlePhoneToggle}
            >
              <FontAwesomeIcon className="fa-stack-2x" icon={faCircle} />
              <FontAwesomeIcon
                className="fa-stack-1x"
                icon={faPhone}
                inverse
                rotation={90}
              />
            </span>
            <a
              href="tel:8009994301"
              className={`phone-number slide-in-right${
                phoneIsActive ? "" : " d-none"
              }`}
            >
              800-999-4301
            </a>
            <div
              role="button"
              aria-label="Navigation"
              tabIndex={0}
              className={`hide-outline menu-icon${!phoneIsActive ? "" : " d-none"}`}
              onClick={(event) => handleMenuToggle(event, !menuIsActive)}
              onKeyPress={(event) => handleMenuToggle(event, !menuIsActive)}
            >
              <div className="menu">
                <div className="rectangle" />
                <div className="rectangle" />
                <div className="rectangle" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav className={`nav-primary${menuIsActive ? " opened" : ""}`}>
        <div className="nav-header">
          <div className="container d-flex justify-content-between">
            <div className="social-icons-right">
              <SocialMenu />
            </div>
            <Menu index={1} handleMenuToggle={handleMenuToggle} />
          </div>
        </div>
        <div className="nav-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h3>Get in Touch</h3>
                <div itemScope itemType="http://schema.org/LocalBusiness">
                  <a href="mailto:hello@findcanary.com">hello@findcanary.com</a>
                  <br />
                  <a href="tel:8009994301">800-999-4301</a>
                  <br />
                  <br />
                  FindCanary - Commerce Canary, LLC
                  <br />
                  155 Summer St Sheboygan Falls, WI 53085
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default TopNavigation

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

const SocialMenu = () => {
  return (
    <>
      <a
        href="https://www.facebook.com/findcanary"
        className="social-icon"
        aria-label="Facebook - FindCanary"
      >
        <FontAwesomeIcon icon={faFacebookSquare}  data-fa-i2svg />
      </a>
      <a
        href="https://twitter.com/FindCanary"
        className="social-icon"
        aria-label="Check out FindCanary on Twitter"
      >
        <FontAwesomeIcon icon={faTwitter} data-fa-i2svg />
      </a>
      <a
        href="https://www.instagram.com/findcanary/"
        className="social-icon"
        aria-label="Check out FindCanary on Instagram"
      >
        <FontAwesomeIcon icon={faInstagram} data-fa-i2svg />
      </a>
      <a
        href="https://www.linkedin.com/company/findcanary"
        className="social-icon"
        aria-label="Learn aboout how FindCanary connects with other businesses on LinkedIn"
      >
        <FontAwesomeIcon icon={faLinkedin} data-fa-i2svg />
      </a>
    </>
  )
}

export default SocialMenu

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UniversalLink from "./UniversalLink"

const Menu = ({ index, handleMenuToggle }) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: PRIMARY_NAVIGATION }) {
        name
        menuItems {
          nodes {
            label
            url
            path
            databaseId
          }
        }
      }
    }
  `)

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return (
    <nav className="menu-menu-1-container" role="navigation">
      <ul className={`menu-menu-${index} nav`}>
        {wpMenu.menuItems.nodes.map((menuItem) => {
          const path = menuItem?.path ?? menuItem.url
          const itemId = `menu-item-${menuItem.databaseId}`

          return (
            <li
              key={itemId}
              className={`${itemId} menu-item menu-item-type-post_type menu-item-object-page`}
            >
              <UniversalLink
                to={path}
                activeClassName="current-menu-item current_page_item"
                onClick={
                  handleMenuToggle
                    ? (event) => handleMenuToggle(event, false)
                    : null
                }
                onKeyUp={
                  handleMenuToggle
                    ? (event) => handleMenuToggle(event, false)
                    : null
                }
              >
                {menuItem.label}
              </UniversalLink>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Menu

import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import addToMailchimp from "gatsby-plugin-mailchimp"
import sanitize from "xss"
import Menu from "./Menu"
import SocialMenu from "./SocialMenu"
import iconMagentoCorp from "../assets/svgs/magento_corp.svg"
import iconMagemojo from "../assets/svgs/magemojo.svg"
import iconSubscribepro from "../assets/svgs/subscribepro.svg"
import iconShipstation from "../assets/svgs/shipstation.svg"
import iconAvalaraTax from "../assets/svgs/avalara_tax.svg"
import iconBgLogo from "../assets/svgs/bg-logo.svg"
import iconFooterLogo from "../assets/svgs/footer-logo.svg"

const Footer = () => {
  let { imageJourney } = useStaticQuery(graphql`
    {
      imageJourney: file(name: { eq: "newsletter-bg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 82) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  imageJourney = imageJourney?.childImageSharp?.fluid

  const [email, setEmail] = useState("")
  const [result, setResult] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const mailChimpResult = await addToMailchimp(email)
    setResult(mailChimpResult)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <>
      <section id="partners">
        <div className="container">
          <h2 className="text-center strong">Our Partners</h2>
          <div className="row d-lg-flex justify-content-between">
            <div className="col-12 col-lg d-flex align-items-center justify-content-center">
              <img
                src={iconMagentoCorp}
                alt="Our Magento Community Insider Partnership"
              />
            </div>
            <div className="col-12 col-lg d-flex align-items-center justify-content-center">
              <img src={iconMagemojo} alt="Magento Hosting with MageMojo" />
            </div>
            <div className="col-12 col-lg d-flex align-items-center justify-content-center">
              <img
                src={iconSubscribepro}
                alt="Subscription Solutions for Magento 1 & 2"
              />
            </div>
            <div className="col-12 col-lg d-flex align-items-center justify-content-center">
              <img
                src={iconShipstation}
                alt="Shipping Solutions and Integrations for Ecommerce"
              />
            </div>
            <div className="col-12 col-lg d-flex align-items-center justify-content-center">
              <img
                src={iconAvalaraTax}
                alt="Solving ecommerce taxation for online retailers"
              />
            </div>
            <div className="col-12 col-lg d-flex align-items-center justify-content-centers">
              <img
                src={iconBgLogo}
                alt="BigCommerce paving the way in SaaS ecommerce"
              />
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage
        Tag="section"
        fluid={imageJourney}
        id="newsletter-signup"
        className="lazy"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-center text-lg-left">
              <h2 className="strong">
                Find out the latest on e-commerce and Magento trends.
              </h2>
              <form
                className="d-lg-block d-flex align-items-center justify-content-center "
                onSubmit={handleSubmit}
              >
                <input
                  aria-label="Newsletter Signup Email"
                  type="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                />
                <input type="submit" id="newsletter-button" className="hide-outline" value="Signup" />
                <p
                  className="newsletter-msg form-text text-white"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(result?.msg),
                  }}
                />
              </form>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <footer className="content-info dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <img
                src={iconFooterLogo}
                alt="FindCanary - Magento Commerce Leaders"
                className="img-fluid"
                id="footer-logo"
                style={{ marginBottom: 24 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-1">
              <div className="footer-section">
                <p className="lead">
                  We design and develop solutions that allow you to not only
                  find but celebrate success.
                </p>
              </div>
              <div className="footer-section">
                <h3>Menu</h3>
                <Menu index={2} />
              </div>
              <div
                className="footer-section d-none d-lg-block"
                style={{ marginTop: -24 }}
              >
                <span className="text-color-light">
                  &copy; 2020 FindCanary - Commerce Canary, LLC
                </span>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-2">
              <div className="footer-section">
                <h3>Connect With Us</h3>
                <SocialMenu />
              </div>
              <div className="footer-section">
                <h3>Get in Touch</h3>
                <div itemScope itemType="http://schema.org/LocalBusiness">
                  <div className="pb-2 pt-2" itemProp="telephone">
                    <a className="pb-3 pt-3" href="tel:8009994301">
                      800-999-4301
                    </a>
                  </div>
                  <div className="pb-2 pt-2" itemProp="email">
                    <a className="pb-3 pt-3" href="mailto:hello@findcanary.com">
                      hello@findcanary.com
                    </a>
                  </div>
                  <div
                    className="pb-2 pt-2"
                    itemType="http://schema.org/PostalAddress"
                    itemScope=""
                    itemProp="address"
                  >
                    <span itemProp="streetAddress">155 Summer St</span>{" "}
                    <span itemProp="addressLocality">Sheboygan Falls</span>,{" "}
                    <span itemProp="addressRegion">WI</span>
                    <span itemProp="postalCode">53085</span>
                  </div>
                  <div
                    itemType="http://schema.org/GeoCoordinates"
                    itemScope=""
                    itemProp="geo"
                  >
                    <meta itemProp="latitude" content="43.724526" />
                    <meta itemProp="longitude" content="-87.817096" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Gradient for Instagram icon hover */}
        <svg width="0" height="0">
          <radialGradient id="ig" r="150%" cx="30%" cy="107%">
            <stop stopColor="#fdf497" offset="0" />
            <stop stopColor="#fdf497" offset="0.05" />
            <stop stopColor="#fd5949" offset="0.45" />
            <stop stopColor="#d6249f" offset="0.6" />
            <stop stopColor="#285AEB" offset="0.9" />
          </radialGradient>
        </svg>
      </footer>
    </>
  )
}

export default Footer

import React, { useEffect, useState } from "react"
import TopNavigation from "./TopNavigation"
import Footer from "./Footer"

const Layout = ({ children, bodyClass }) => {
  const [menuIsActive, setMenuIsActive] = useState(false)

  const handleMenuToggle = (e, active) => {
    if (document.body) {
      document.body.className = active ? "menu-overflow" : ""
    }
    setMenuIsActive(active)
    e.stopPropagation()
  }

  const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing")
      window.removeEventListener("keydown", handleFirstTab)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab)

    return () => {
      window.removeEventListener("keydown", handleFirstTab)
    }
  }, [])

  return (
    <div
      id="GatsbyBody"
      className={`${bodyClass ?? ""} ${menuIsActive ? "menu-overflow" : ""}`}
    >
      <TopNavigation
        menuIsActive={menuIsActive}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="wrap" role="document">
        <div className="content">
          <main id="site-content" className="main" role="main">
            {children}
          </main>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
